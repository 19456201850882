<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? LocalSalesInvoice__title : ''" help-page-component="AddLocalSalesInvoice"></appic-page-title-bar>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <LocalSalesInvoiceAlert
                        :alert="$t('message.closeAlert')"
                        mode="add"
                        type="warning"
                        class="mt-3"
                        v-if="addMode && newLocalSalesInvoiceUnsaved"
                />
                <LocalSalesInvoiceAlert
                        :alert="$t('message.closeAlert')"
                        mode="update"
                        type="warning"
                        class="mt-3"
                        v-if="updateMode && updatedLocalSalesInvoiceUnsaved"
                />
                <app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="localSalesInvoiceform" v-model="validForm">
                        <v-overlay
                                :value="loader"
                                absolute
                                opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                            color="primary"
                                            indeterminate
                                            size="40"
                                            width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                                accordion
                                multiple
                                v-model="panel"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <WarehouseInvoicesGeneralInfo v-if="loader"/>
                                    <template v-if="!loader">
                                        <v-layout row pt-3 v-if="addMode == true">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedSalesOrder') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items="allUnInvoicedSalesOrders"
                                                        :label="LocalSalesInvoice__SalesOrder__id ? '' : $t('message.required')"
                                                        :loading="loading.salesOrder"
                                                        :rules="[...validationRules.required]"
                                                        :value="LocalSalesInvoice__SalesOrder__id"
                                                        autocomplete="off"
                                                        dense
                                                        hide-details="auto"
                                                        item-value="SalesOrder.id"
                                                        item-text="SalesOrder.title"
                                                        required
                                                        solo
                                                        @click="loading.salesOrder = true"
                                                        @blur="loading.salesOrder = false"
                                                        @change="loadSalesOrder($event)"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-1">
                                                            <span class="font-weight-bold">{{ item.SalesOrder.title }}</span>
                                                            <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.SalesOrder.Customer.title }} </span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row pt-6 v-if="updateMode == true">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveInvoice') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-autocomplete
                                                        :items="allActiveLocalSalesInvoices"
                                                        :placeholder="$t('message.startTypingToSearch')"
                                                        dense
                                                        hide-details="auto"
                                                        item-value="LocalSalesInvoice.id"
                                                        item-text="LocalSalesInvoice.title"
                                                        required
                                                        solo
                                                        @change="loadAnotherActiveLocalSalesInvoice($event)"
                                                >
                                                    <template v-slot:item="{item}">
                                                        <div class="d-flex flex-column py-1">
                                                            <span class="font-weight-bold">{{ item.LocalSalesInvoice.title}}</span>
                                                            <span class="font-xs"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                            <span class="font-xs"> {{ $t('message.soNo') + ': ' + item.SalesOrder.title }} </span>
                                                        </div>
                                                    </template>
                                                </v-autocomplete>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="updateMode == true">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedSalesOrder') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>{{ LocalSalesInvoice__SalesOrder__title }}</v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.date') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-menu
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        max-width="290px"
                                                        min-width="290px"
                                                        ref="localSalesInvoiceDateMenu"
                                                        transition="scale-transition"
                                                        v-model="localSalesInvoiceDateMenu"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :label="LocalSalesInvoice__localsaledate ? '' : $t('message.required')"
                                                                :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                                :value="computedLocalSalesInvoiceDateFormatted"
                                                                append-icon="event"
                                                                clearable
                                                                class="force-text-left"
                                                                hide-details="auto"
                                                                background-color="white"
                                                                persistent-hint
                                                                readonly
                                                                required
                                                                solo
                                                                v-on="on"
                                                                @click:clear="localSalesInvoiceDateMenu = null"
                                                                @click:append="localSalesInvoiceDateMenu = true"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                            v-model="LocalSalesInvoice__localsaledate"
                                                            @input="localSalesInvoiceDateMenu = false"
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.appTimberCompany') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="whOffices"
                                                        :label="LocalSalesInvoice__office_id ? '' : $t('message.required')"
                                                        autocomplete="off"
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Office.title"
                                                        item-value="Office.id"
                                                        readonly
                                                        solo
                                                        v-model="LocalSalesInvoice__office_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="filteredBuyers"
                                                        :label="LocalSalesInvoice__customer_id ? '' : $t('message.required')"
                                                        autocomplete="off"
                                                        dense
                                                        hide-details
                                                        item-value="Customer.id"
                                                        item-text="Customer.title"
                                                        readonly
                                                        solo
                                                        v-model="LocalSalesInvoice__customer_id"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.attention') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="buyerContacts"
                                                        autocomplete="off"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Contact.name"
                                                        item-value="Contact.id"
                                                        solo
                                                        v-model="LocalSalesInvoice__contact_id"
                                                >
                                                    <template v-slot:item="{ item }">
                                                        <div class="d-flex flex-column pt-3">
                                                            <span class="font-weight-bold">{{ (item.Contact.prefix ? item.Contact.prefix + " ": "") + item.Contact.name }}</span>
                                                            <span class="font-xs" v-if="item.Contact.position != null">{{ item.Contact.position }}</span>
                                                        </div>
                                                    </template>
                                                    <template v-slot:selection="{ item }">
                                                        <div class="d-flex flex-column">
                                                            <span class="font-weight-bold">{{ (item.Contact.prefix ? item.Contact.prefix + " ": "") + item.Contact.name }}</span>
                                                        </div>
                                                    </template>
                                                </v-select>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.invoiceNo') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-text-field
                                                        :value="LocalSalesInvoice__title"
                                                        :label="LocalSalesInvoice__title ? '' : $t('message.required')"
                                                        :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                                        autocomplete="off"
                                                        class="force-text-left"
                                                        dense
                                                        hide-details="auto"
                                                        required
                                                        solo
                                                        @change="LocalSalesInvoice__title = $event"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.collectionDate') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-menu
                                                        :close-on-content-click="false"
                                                        :nudge-right="40"
                                                        max-width="290px"
                                                        min-width="290px"
                                                        ref="localSalesInvoicePickupDateMenu"
                                                        transition="scale-transition"
                                                        v-model="localSalesInvoicePickupDateMenu"
                                                >
                                                    <template v-slot:activator="{ on }">
                                                        <v-text-field
                                                                :label="LocalSalesInvoice__pickup_date ? '' : $t('message.required')"
                                                                :value="computedLocalSalesPickupDateFormatted"
                                                                :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                                append-icon="event"
                                                                clearable
                                                                class="force-text-left"
                                                                hint="DD/MM/YY format"
                                                                hide-details="auto"
                                                                background-color="white"
                                                                persistent-hint
                                                                readonly
                                                                solo
                                                                v-on="on"
                                                                @click:clear="computedLocalSalesPickupDateFormatted = null"
                                                                @click:append="localSalesInvoicePickupDateMenu = true"
                                                        />
                                                    </template>
                                                    <v-date-picker
                                                            first-day-of-week="1"
                                                            locale-first-day-of-year="4"
                                                            show-week
                                                            v-model="LocalSalesInvoice__pickup_date"
                                                            @input="localSalesInvoicePickupDateMenu = false"
                                                    />
                                                </v-menu>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.deliveryTerms') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1>
                                                <v-select
                                                        :items="deliveryTerms"
                                                        :rules="[...validationRules.required]"
                                                        autocomplete="new-password"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        v-model="LocalSalesInvoice__json__delivery_terms"
                                                />
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row v-if="LocalSalesInvoice__item_list_version > 1.0">
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.measurementSystem') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                                <v-radio-group
                                                        class="mt-0"
                                                        dense
                                                        hide-details="auto"
                                                        row
                                                        v-model="LocalSalesInvoice__ms_id"
                                                >
                                                    <v-radio :label="$t('message.metric')" :value="1"/>
                                                    <v-radio :label="$t('message.imperial')" :value="2"/>
                                                </v-radio-group>
                                                <div class="font-sm font-italic">({{ $t('message.switchToFilterThicknessAndUnits') }})</div>
                                            </v-flex>
                                        </v-layout>
                                        <v-layout row>
                                            <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.posted') }}</v-flex>
                                            <v-flex xs6 lg4 md6 py-1 class="font-weight-bold">{{ LocalSalesInvoice__is_posted == 1 ? $t('message.yes'): $t('message.no') }}</v-flex>
                                        </v-layout>
                                        <v-layout row v-if="LocalSalesInvoice__office_id == 1 && updateMode">
                                            <template v-if="false">
                                                <v-flex xs6 lg2 md3 pl-5 py-1 class=" align-center">{{ $t('message.lhdnValidated') }}</v-flex>
                                                <template v-if="lhdn.validated && lhdn.submitted">
                                                    <v-flex xs6 lg3 md4 py-1>
                                                        <div class="d-flex flex-row align-center">
                                                            <span class="font-weight-bold">{{ $t('message.yes') }}</span>
                                                            <span class="font-weight-bold ml-5">{{ lhdn.dateValidated }}</span>
                                                            <v-tooltip bottom>
                                                                <template  v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        :href="lhdn.validationLink"
                                                                        class="ml-5"
                                                                        target="_blank"
                                                                        icon
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                    >
                                                                        <v-icon color="green darken-2">mdi-link</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>{{ $t('message.validationLink') }}</span>
                                                            </v-tooltip>
                                                        </div>
                                                    </v-flex>
                                                </template>
                                                <template v-else-if="!lhdn.validated && lhdn.submitted">
                                                    <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row align-center font-weight-bold">
                                                        {{ $t('message.submitted') }}
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-btn :loading="loading.updatingLhdnStatus" icon @click="updateLhdnStatus()" v-on="on">
                                                                    <v-icon color="success darken-1">refresh</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <span>{{ $t('message.updateStatus') }}</span>
                                                        </v-tooltip>
                                                    </v-flex>
                                                </template>
                                                <template v-else>
                                                    <v-flex xs6 lg4 md6 py-1 class="font-weight-bold">{{ $t('message.no') }}</v-flex>
                                                </template>
                                            </template>
                                        </v-layout>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.specifications') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-switch :true-value="true" v-model="editByDialog" dense hide-details="auto" :label="$t('message.editByDialog')"></v-switch>
                                    <Transition>
                                        <WarehouseInvoiceItemList v-if="loader"/>
                                    </Transition>
                                    <v-overlay :value="loading.items" opacity="0.15">
                                        <v-row>
                                            <v-col class="text-center">
                                                <v-progress-circular
                                                    color="primary"
                                                    indeterminate
                                                    size="40"
                                                    width="6"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-overlay>
                                    <template v-if="editByDialog">
                                        <LocalSalesInvoiceItemListV3 :update-mode="updateMode" @reload-item-list="reloadItemList" v-if="!loader"/>
                                    </template>
                                    <template v-else>
                                        <LocalSalesInvoiceItemListNew :update-mode="updateMode" v-if="!loader && LocalSalesInvoice__item_list_version == 1.0"/>
                                        <LocalSalesInvoiceItemListV2 :update-mode="updateMode" v-if="!loader && LocalSalesInvoice__item_list_version > 1.0"/>
                                    </template>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.marketing') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                    :items="allActiveSuspendedSuppliers"
                                                    :label="LocalSalesInvoice__supplier_id ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Supplier.title"
                                                    item-value="Supplier.id"
                                                    solo
                                                    v-model="LocalSalesInvoice__supplier_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.salesColleague') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                    :items="allSalesColleagues"
                                                    :label="LocalSalesInvoice__salescontact_id ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Salesperson.name"
                                                    item-value="Salesperson.id"
                                                    required
                                                    solo
                                                    v-model="LocalSalesInvoice__salescontact_id"
                                            >
                                                <template v-slot:item="data">
                                                    <template>
                                                        <v-list-item-avatar>
                                                            <img :src="data.item.User.avatar ? data.item.User.avatar : '/static/avatars/appic_user.jpg'">
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-html="data.item.Salesperson.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-html="data.item.Country.name"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.destination') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                :items="filteredCountries"
                                                :label="LocalSalesInvoice__destinatione_id ? '' : $t('message.required')"
                                                :rules="[...validationRules.required]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="Country.name"
                                                item-value="Country.id"
                                                required
                                                solo
                                                v-model="LocalSalesInvoice__destinatione_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.product') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                :items="allProductGroups"
                                                :label="LocalSalesInvoice__productgroup_id ? '' : $t('message.required')"
                                                :rules="[...validationRules.required]"
                                                clearable
                                                dense
                                                hide-details="auto"
                                                item-text="ProductGroup.title"
                                                item-value="ProductGroup.id"
                                                required
                                                solo
                                                v-model="LocalSalesInvoice__productgroup_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.remarks') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-textarea
                                                    :rules="[...validationRules.paragraph]"
                                                    :value="LocalSalesInvoice__remarks"
                                                    autocomplete="new-password"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="LocalSalesInvoice__remarks = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.finance') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.paymentTerms') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                    :items="buyerPaymentTerms"
                                                    :label="LocalSalesInvoice__paymentstatement_id ? '' : $t('message.required')"
                                                    clearable
                                                    dense
                                                    hide-details
                                                    item-value="Paymentterm.id"
                                                    item-text="Paymentterm.title"
                                                    required
                                                    solo
                                                    v-model="LocalSalesInvoice__paymentstatement_id"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                                        <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.bankAccount') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                    :items="filteredBankAccounts"
                                                    clearable
                                                    dense
                                                    item-value="Bank.id"
                                                    item-text="Bank.title"
                                                    hide-details
                                                    solo
                                                    v-model="LocalSalesInvoice__bank_id"
                                            >
                                                <template v-slot:item="{item}">
                                                    <div class="d-flex flex-column py-2">
                                                        <span class="font-sm">{{ item.Bank.title }}</span>
                                                        <span class="font-weight-bold"> {{ item.Bank.account }} </span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{item}">
                                                    <div class="d-flex flex-column py-0">
                                                        <span class="font-sm">{{ item.Bank.title + ':' }}</span>
                                                        <span class="font-weight-bold">{{ item.Bank.account }} </span>
                                                    </div>
                                                </template>
                                            </v-select>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.gstVatPpnRate') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                    :items="gstRates"
                                                    clearable
                                                    dense
                                                    hide-details
                                                    item-value="Gstrate.value"
                                                    item-text="Gstrate.title"
                                                    solo
                                                    v-model="LocalSalesInvoice__gstpercent"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1  class="align-center">{{ $t('message.gpTaxCode') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                    :items="taxCodes"
                                                    dense
                                                    hide-details
                                                    solo
                                                    v-model="LocalSalesInvoice__headertaxschedule"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel v-if="LocalSalesInvoice__office_id">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.indonesiaSales') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-layout row pt-3>
                                        <v-flex xs12 pl-5 py-1 class="align-center font-weight-bold">{{ $t('message.indonesia.travelDocument') }}</v-flex>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.indonesia.travelDocumentNo') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-text-field
                                                :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                :value="LocalSalesInvoice__Indonesia['travel_document']['number']"
                                                autocomplete="off"
                                                class="force-text-left"
                                                dense
                                                hide-details="auto"
                                                required
                                                solo
                                                @change="updateIndonesiaDocument('travel_document.number', $event)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.indonesia.forwarding') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-text-field
                                                :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                :value="LocalSalesInvoice__Indonesia['travel_document']['forwarding']"
                                                autocomplete="off"
                                                class="force-text-left"
                                                dense
                                                hide-details="auto"
                                                required
                                                solo
                                                @change="updateIndonesiaDocument('travel_document.forwarding', $event)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.indonesia.truckNumber') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-text-field
                                                :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                :value="LocalSalesInvoice__Indonesia['travel_document']['truck_number']"
                                                autocomplete="off"
                                                class="force-text-left"
                                                dense
                                                hide-details="auto"
                                                required
                                                solo
                                                @change="updateIndonesiaDocument('travel_document.truck_number', $event)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.indonesia.driver') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-text-field
                                                :rules="[...validationRules.plainTextWithNumberAndSymbols]"
                                                :value="LocalSalesInvoice__Indonesia['travel_document']['driver']"
                                                autocomplete="off"
                                                class="force-text-left"
                                                dense
                                                hide-details="auto"
                                                required
                                                solo
                                                @change="updateIndonesiaDocument('travel_document.driver', $event)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.printPreferences') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-layout row pt-3>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.printTwoPages') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-checkbox
                                                class="mt-0"
                                                dense
                                                hide-details
                                                required
                                                solo
                                                :true-value="1"
                                                :false-value="null"
                                                v-model="LocalSalesInvoice__longformat"
                                            />
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_localsale')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="localsales" :asset_id="LocalSalesInvoice__id"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-layout row>
                            <v-flex d-flex xs12>
                                <v-layout row px-3 pt-3 mb-3>
                                    <v-btn
                                            class="ml-3"
                                            small
                                            v-if="false"
                                            @click.stop="previewDialog = true"
                                    >
                                        {{ $t('message.previewSalesOrder') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.reload"
                                            class="ml-0"
                                            color="warning"
                                            small
                                            @click="reloadForm()"
                                            v-if="addMode == true && itemQuantityErrors"
                                    >
                                        {{ $t('message.reload') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.add"
                                            class="ml-0"
                                            color="info"
                                            small
                                            @click="addLocalSalesInvoice()"
                                            v-if="addMode == true && itemQuantityErrors == false"
                                    >
                                        {{ $t('message.create') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.save"
                                            class="ml-3"
                                            color="info"
                                            small
                                            @click="saveLocalSalesInvoice()"
                                            v-if="updateMode == true && LocalSalesInvoice__is_posted != 1"
                                    >
                                        {{ $t('message.save') }}
                                    </v-btn>
                                    <v-btn
                                            :loading="loading.save"
                                            class="mr-3"
                                            color="info"
                                            small
                                            @click="saveLocalSalesInvoice()"
                                            v-if="updateMode == true && superAdmin && LocalSalesInvoice__is_posted == 1"
                                    >
                                        {{ $t('message.save') }}
                                    </v-btn>
                                    <template v-if="LocalSalesInvoice__longformat == 1 && updateMode == true && LocalSalesInvoice__item_list_version > 1.0">
                                        <PrintLocalSaleButtons
                                            :version="LocalSalesInvoice__item_list_version"
                                            :is-posted="LocalSalesInvoice__is_posted"
                                            :local-sale-id="LocalSalesInvoice__id"
                                            :office-id="LocalSalesInvoice__office_id"
                                            :update-mode="updateMode"
                                            :list-mode="false"
                                        />
                                    </template>
                                    <template v-else-if="LocalSalesInvoice__longformat == 1 && updateMode == true && LocalSalesInvoice__item_list_version == 1.0">
                                        <v-btn
                                            :class="LocalSalesInvoice__is_posted != 1 ? 'ml-3' : 'ml-0'"
                                            small
                                            @click.stop="viewPdf('si')"
                                        >
                                            <v-icon small>far fa-file-pdf</v-icon>
                                            <span class="ml-1">{{ $t('message.invoice') }}</span>
                                        </v-btn>
                                        <v-btn
                                            class="ml-3"
                                            small
                                            @click.stop="viewPdf('do')"
                                        >
                                            <v-icon small>far fa-file-pdf</v-icon>
                                            <span class="ml-1" v-if="LocalSalesInvoice__office_id == 21">{{ $t('message.packingList') }}</span>
                                            <span class="ml-1" v-else>{{ $t('message.deliveryOrder') }}</span>
                                        </v-btn>
                                        <v-btn
                                            class="ml-3"
                                            small
                                            @click.stop="viewPdf('ro')"
                                        >
                                            <v-icon small>far fa-file-pdf</v-icon>
                                            <span class="ml-1">{{ $t('message.releaseOrder') }}</span>
                                        </v-btn>
                                    </template>
                                    <template v-else>
                                        <PrintLocalSaleButtons
                                            :version="LocalSalesInvoice__item_list_version"
                                            :is-posted="LocalSalesInvoice__is_posted"
                                            :local-sale-id="LocalSalesInvoice__id"
                                            :office-id="LocalSalesInvoice__office_id"
                                            :update-mode="updateMode"
                                            :list-mode="false"
                                        />
                                    </template>
                                    <v-btn
                                        :loading="loading.cancel"
                                        class="ml-3"
                                        color="error"
                                        small
                                        v-if="updateMode == true"
                                        @click="cancelLocalSalesInvoice(LocalSalesInvoice__id)"
                                    >
                                        {{ $t('message.cancel') }}
                                    </v-btn>
                                    <v-btn
                                        :loading="loading.xero"
                                        class="ml-3"
                                        small
                                        v-if="updateMode == true && LocalSalesInvoice__is_posted != 1"
                                        @click="uploadToXero(LocalSalesInvoice__id)"
                                    >
                                        {{ $t('message.uploadToXero') }}
                                    </v-btn>
                                    <template>
                                        <v-btn
                                            :disabled="lhdn.submitted"
                                            :loading="loading.lhdn"
                                            class="ml-3"
                                            small
                                            v-if="updateMode == true && LocalSalesInvoice__office_id == 1 && lhdn.validated == false && lhdn.submitted == false"
                                            @click="submitToLhdn(LocalSalesInvoice__id)"
                                        >{{ $t('message.submitToLhdn') }}
                                        </v-btn>
                                    </template>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <SimpleAlert
                :alert_message.sync="dialogs.error_message"
                :dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        />
        <v-dialog
                overlay=false
                scrollable
                transition="dialog-bottom-transition"
                v-model="previewDialog"
                width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.salesOrderPreview') }}</v-card-title>
                <v-card-text class="pa-3">
                </v-card-text>
                <v-card-actions>
                    <v-card-actions class="pr-3 pt-3">
                        <v-spacer></v-spacer>
                        <v-btn
                                class="ml-3"
                                color="default"
                                small
                                @click="previewDialog = false"
                        >{{ $t('message.backToEditing') }}
                        </v-btn>
                        <v-btn
                                :loading="loading.add"
                                class="ml-3"
                                color="info"
                                small
                                v-if="addMode == true"
                                @click="addLocalSalesInvoice()"
                        >{{ $t('message.create') }}
                        </v-btn>
                        <v-btn
                                :loading="loading.save"
                                class="ml-3"
                                color="info"
                                small
                                v-if="addMode == false"
                                @click="saveLocalSalesInvoice()"
                        >{{ $t('message.save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {formatDate, isObject, log, numberFormat, scrollToFirstFormValidationError} from "Helpers/helpers";
    import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
    import {mapFields} from "vuex-map-fields";

    import {api} from "Api/index";
    import LocalSalesInvoiceItemListNew from "Components/Appic/LocalSalesInvoiceItemListNew";
    import router from "@/router";
    import { v4 as uuidv4 } from 'uuid';
    import WarehouseInvoicesGeneralInfo from "Components/Appic/Loading/WarehouseInvoiceGeneralInfo";
    import WarehouseInvoiceItemList from "Components/Appic/Loading/WarehouseInvoiceItemList";

    const DocumentHistory = () => import("Components/Appic/DocumentHistory");
    const LocalSalesInvoiceAlert = () => import("Components/Appic/LocalSalesInvoiceAlert");
    const SimpleAlert = () => import("Components/Appic/SimpleAlert");
    const PrintLocalSaleButtons = () => import("Components/Appic/Printing/PrintLocalSaleButtons");
    const LocalSalesInvoiceItemListV2 = () => import("Components/Appic/LocalSalesInvoiceItemListV2");
    const LocalSalesInvoiceItemListV3 = () => import("Components/Appic/LocalSalesInvoiceItemListV3");

    export default {
        name: "AddLocalSalesInvoice",
        components: {
            DocumentHistory,
            LocalSalesInvoiceAlert,
            LocalSalesInvoiceItemListNew,
            LocalSalesInvoiceItemListV2,
            LocalSalesInvoiceItemListV3,
            PrintLocalSaleButtons,
            SimpleAlert,
            WarehouseInvoiceItemList,
            WarehouseInvoicesGeneralInfo
        },
        props: ['salesOrderId','localSalesInvoiceId'],
        data () {
            return {
                allActiveLocalSalesInvoices: [],
                allUnInvoicedSalesOrders: [],
                buyerContacts: [],
                buyerPaymentTerms: [],
                deliveryTerms: [
                    {text: "Own collection", value: "Own collection"},
                    {text: "By APP Timber transport", value: "By APP Timber transport"}
                ],
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                editByDialog: true,
                filteredBuyers: [],
                itemQuantityErrors: false,
                lhdn: {
                    jobId: null,
                    submitted: false,
                    validated: false,
                    dateValidated: null,
                    validationLink: null
                },
                loading: {
                    add: false,
                    items: false,
                    lhdn: false,
                    post: false,
                    reload: false,
                    reset: false,
                    salesOrder: false,
                    save: false,
                    updatingLhdnStatus: false,
                    xero: false
                },
                loader: false,
                localSalesInvoiceDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
                localSalesInvoiceDateMenu: false,
                localSalesInvoicePickupDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
                localSalesInvoicePickupDateMenu: false,
                pageKey: 0,
                panel: [0,1],
                previewDialog: false,
                validForm: false
            }
        },
        computed: {
            statePrefix() {
                if (this.updateMode) {
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('localsalesinvoice',{
                LocalSalesInvoice__bank_id: 'LocalSalesInvoice.bank_id',
                LocalSalesInvoice__contact_id: 'LocalSalesInvoice.contact_id',
                LocalSalesInvoice__currency_id: 'LocalSalesInvoice.currency_id',
                LocalSalesInvoice__customer_id: 'LocalSalesInvoice.customer_id',
                LocalSalesInvoice__destinatione_id: 'LocalSalesInvoice.destinatione_id',
                LocalSalesInvoice__gstpercent: 'LocalSalesInvoice.gstpercent',
                LocalSalesInvoice__headertaxschedule: 'LocalSalesInvoice.headertaxschedule',
                LocalSalesInvoice__id: 'LocalSalesInvoice.id',
                LocalSalesInvoice__item_list_version: 'LocalSalesInvoice.item_list_version',
                LocalSalesInvoice__is_posted: 'LocalSalesInvoice.is_posted',
                LocalSalesInvoice__json__delivery_terms: 'LocalSalesInvoice.json.delivery_terms',
                LocalSalesInvoice__localsaledate: 'LocalSalesInvoice.localsaledate',
                LocalSalesInvoice__longformat: 'LocalSalesInvoice.longformat',
                LocalSalesInvoice__ms_id: 'LocalSalesInvoice.ms_id',
                LocalSalesInvoice__office_id: 'LocalSalesInvoice.office_id',
                LocalSalesInvoice__origin_id: 'LocalSalesInvoice.origin_id',
                LocalSalesInvoice__paymentstatement_id: 'LocalSalesInvoice.paymentstatement_id',
                LocalSalesInvoice__paymentterm: 'LocalSalesInvoice.paymentterm',
                LocalSalesInvoice__paymentterm_id: 'LocalSalesInvoice.paymentterm_id',
                LocalSalesInvoice__pickup_date: 'LocalSalesInvoice.pickup_date',
                LocalSalesInvoice__posted: 'LocalSalesInvoice.posted',
                LocalSalesInvoice__posted_by: 'LocalSalesInvoice.posted_by',
                LocalSalesInvoice__productgroup_id: 'LocalSalesInvoice.productgroup_id',
                LocalSalesInvoice__remarks: 'LocalSalesInvoice.remarks',
                LocalSalesInvoice__salescontact_id: 'LocalSalesInvoice.salescontact_id',
                LocalSalesInvoice__supplier_id: 'LocalSalesInvoice.supplier_id',
                LocalSalesInvoice__title: 'LocalSalesInvoice.title',
                LocalSalesInvoice__Customer__ls_paymentstatement_id: 'LocalSalesInvoice.Customer.ls_paymentstatement_id',
                LocalSalesInvoice__SalesOrder__id: 'LocalSalesInvoice.SalesOrder.id',
                LocalSalesInvoice__SalesOrder__title: 'LocalSalesInvoice.SalesOrder.title',
                LocalSalesInvoice__Indonesia: 'LocalSalesInvoice.id_doc_vars',
            }, 'statePrefix'),
            ...mapFields('localsalesinvoice',{
                defaultPaymentStatementId: 'defaultPaymentStatementId',
                newLocalSalesInvoiceSaved: 'newLocalSalesInvoiceSaved',
                newLocalSalesInvoiceUnsaved: 'newLocalSalesInvoiceUnsaved',
                updatedLocalSalesInvoiceUnsaved: 'updatedLocalSalesInvoiceUnsaved',
                updatedLocalSalesInvoiceSaved: 'updatedLocalSalesInvoiceSaved'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters('bankaccount', {
                allBankAccounts: 'allBankAccounts',
                findBankAccountById: 'findBankAccountById',
                findBankAccountsByOfficeAndCurrency: 'findBankAccountsByOfficeAndCurrency',
            }),
            ...mapGetters('buyer', {
                allBuyers: 'allBuyers',
                findBuyerById: 'findBuyerById'
            }),
            ...mapGetters('country',{
                allCountries: 'allCountries'
            }),
            ...mapGetters('paymentterm', {
                allBuyerPaymentTerms: 'allBuyerPaymentTerms',
                allBuyerLocalSalesPaymentTerms: 'allBuyerLocalSalesPaymentTerms'
            }),
            ...mapGetters('productgroup', {
                allProductGroups: 'allProductGroups'
            }),
            ...mapGetters('country',{
                allCountries: 'allCountries'
            }),
            ...mapGetters('salescolleague', {
                allSalesColleagues: 'allSalesColleagues'
            }),
            ...mapGetters('supplier',{
                allActiveSuspendedSuppliers: 'allActiveSuspendedSuppliers',
                findSupplierById: 'findSupplierById'
            }),
            ...mapGetters('user',{
                User: 'User',
                superAdmin: 'superAdmin'
            }),
            ...mapGetters([
                'currencies',
                'gstRates',
                'offices',
                // 'productGroups',
                'taxCodes',
                'validationRules'
            ]),
            addMode: function () {
                return this.$route.matched.some(({name}) => name === 'add_localsale')
            },
            computedLocalSalesInvoiceDateFormatted () {
                return formatDate(this.LocalSalesInvoice__localsaledate)
            },
            computedLocalSalesPickupDateFormatted () {
                return formatDate(this.LocalSalesInvoice__pickup_date)
            },
            filteredBankAccounts: function(){
                if(this.LocalSalesInvoice__office_id != null && this.LocalSalesInvoice__currency_id != null){
                    return this.findBankAccountsByOfficeAndCurrency(this.LocalSalesInvoice__office_id, this.LocalSalesInvoice__currency_id)
                } else {
                    return []
                }
            },
            filteredCountries () {
                return this.allCountries.filter(country => {
                    return [1,2,3,4,12].includes(country.Country.id)
                })
            },
            updateMode: function () {
                return this.$route.matched.some(({name}) => name === 'update_localsale')
            },
            whOffices: function () {
                return this.offices.filter(office => office.Office.has_warehouse == true)
            }
        },
        methods: {
            ...mapActions('bankaccount', {
                resetAllBankAccounts: 'resetAllBankAccounts',
                getAllBankAccounts: 'getAllBankAccounts'
            }),
            ...mapActions('buyer',{
                getAllActiveBuyers: 'getAllActiveBuyers'
            }),
            ...mapActions('country',{
                getAllCountries: 'getAllCountries'
            }),
            ...mapActions('paymentterm', {
                getAllPaymentTerms: 'getAllPaymentTerms'
            }),
            ...mapActions('localsalesinvoice',{
                cancelLocalSalesInvoiceById: 'cancelLocalSalesInvoiceById',
                createLocalSalesInvoice: 'createLocalSalesInvoice',
                getAllActiveLocalSalesInvoices: 'getAllActiveLocalSalesInvoices',
                getLocalSalesInvoiceToUpdateById: 'getLocalSalesInvoiceToUpdateById',
                loadUnInvoicedSalesOrder: 'loadUnInvoicedSalesOrder',
                resetAllLocalSalesInvoices: 'resetAllLocalSalesInvoices',
                resetCurrentLocalSalesInvoice: 'resetCurrentLocalSalesInvoice',
                updateLocalSalesInvoice: 'updateLocalSalesInvoice'
            }),
            ...mapActions('localsalesitem',{
                getSalesItemsBySalesOrder: 'getSalesItemsBySalesOrder',
                getSalesItemsByLocalSaleInvoice: 'getSalesItemsByLocalSaleInvoice',
                updateTotals: 'updateTotals'
            }),
            ...mapActions('salescolleague', {
                getAllSalesColleagues: 'getAllSalesColleagues',
            }),
            ...mapActions('supplier',{
                resetAllSuppliers: 'resetAllSuppliers',
                getAllActiveSuspendedSuppliers: 'getAllActiveSuspendedSuppliers'
            }),
            addLocalSalesInvoice () {
                if(this.$refs.localSalesInvoiceform.validate()) {
                    this.loading.add = true
                    this.createLocalSalesInvoice()
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.localSalesInvoiceAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.add = false
                                this.newLocalSalesInvoiceUnsaved = false
                                this.newLocalSalesInvoiceSaved = true
                                this.resetCurrentLocalSalesInvoice()
                                    .then(() => {
                                        this.resetAllLocalSalesInvoices()
                                            .then(() => {
                                                this.$store.commit('localsalesitem/resetState')
                                                router
                                                    .push({
                                                        name: 'update_localsale',
                                                        params: {localSalesInvoiceId: response.localsale_id}
                                                    })
                                                    .catch(err => {
                                                        this.$toast.error(err,
                                                            {
                                                                classes: ['icon-float-left'],
                                                                icon: 'error_outline'
                                                            }
                                                        )
                                                    })
                                            })
                                    })
                            } else {
                                if(isObject(response.message)){
                                    //extract validation errors
                                    const errors = Object.values(response.message)
                                    errors.forEach(errorObj => {
                                        if (Array.isArray(errorObj)) {
                                            errorObj.forEach(error => {
                                                this.$toast.error(error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                        } else {
                                            this.$toast.error(this.$t('message.errors.localSalesInvoiceNotAdded'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        }
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.localSalesInvoiceNotAdded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                                this.loading.add = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.localSalesInvoiceNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.localSalesInvoiceNotAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    scrollToFirstFormValidationError()
                }
            },
            buyerChanged () {
                if(this.LocalSalesInvoice__paymentstatement_id != null){
                    this.buyerPaymentTerms = this.allBuyerLocalSalesPaymentTerms.filter(paymentTerm => {
                        return [this.LocalSalesInvoice__Customer__ls_paymentstatement_id, this.LocalSalesInvoice__paymentstatement_id, this.defaultPaymentStatementId].includes(paymentTerm.Paymentterm.id)
                    })
                    if(this.buyerPaymentTerms.length == 1) this.LocalSalesInvoice__paymentstatement_id = this.defaultPaymentStatementId
                } else {
                    this.buyerPaymentTerms = this.allBuyerLocalSalesPaymentTerms.filter(paymentTerm => {
                        return [this.LocalSalesInvoice__Customer__ls_paymentstatement_id, this.defaultPaymentStatementId].includes(paymentTerm.Paymentterm.id)
                    })
                    if(this.LocalSalesInvoice__Customer__ls_paymentstatement_id != null) {
                        this.LocalSalesInvoice__paymentstatement_id = this.LocalSalesInvoice__Customer__ls_paymentstatement_id
                    } else {
                        this.LocalSalesInvoice__paymentstatement_id = this.defaultPaymentStatementId
                    }
                }

                const buyer = this.findBuyerById(this.LocalSalesInvoice__customer_id)

                if(buyer) {
                    const promise = new Promise((resolve, reject) => {
                        api
                            .get("/companies/" + buyer.Customer.company_id + "/contacts")
                            .then(response => {
                                resolve(response.data.data);
                            })
                            .catch(error => {
                                reject(error);
                            })
                    })
                    promise.then(response => {
                        this.buyerContacts = response
                        if(this.LocalSalesInvoice__contact_id == null) {
                            this.LocalSalesInvoice__contact_id = this.buyerContacts[0]?.Contact.id
                        }
                    })
                }
            },
            async cancelLocalSalesInvoice (val) {
                if(await this.$root.$confirm(this.$t('message.cancelLocalSalesInvoice'), this.$t('message.confirmations.continueLocalSalesInvoiceCancelAction'), {color: 'orange'})) {
                    this.cancelLocalSalesInvoiceById(val)
                        .then(response => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.localSalesInvoiceDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                router.push({name: 'invoice_listing'}).catch(err => {
                                    this.$toast.error(err,
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.localSalesInvoiceNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            }
                            this.loading.cancel = false
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.localSalesInvoiceNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.cancel = false
                        })
                        // .finally(() => {
                        //     this.loading.cancel = false
                        // })
                }
            },
            checkLhdnValidation () {
                if(this.LocalSalesInvoice__office_id == 1) {
                    api
                        .get("/lhdn/ws/" + this.localSalesInvoiceId)
                        .then((response) => {
                            if(response.data.status == 'success') {
                                const lhdn = response.data.data.lhdn;
                                this.lhdn.jobId = lhdn.jobId;
                                this.lhdn.submitted = lhdn.submitted;
                                this.lhdn.validated = lhdn.validated;
                                this.lhdn.dateValidated = lhdn.dateValidated;
                                this.lhdn.validationLink = lhdn.validationLink;
                            } else {
                                // this.$toast.error(response.data.message,
                                //     {
                                //         classes: ['icon-float-left'],
                                //         icon: 'error_outline'
                                //     });
                            }
                            if(this.loading.updatingLhdnStatus) this.loading.updatingLhdnStatus = false;
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.cannotCheckLhdnStatus'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                });
                        })
                }
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            formatDate,
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            getAllUnInvoicedSalesOrders () {
                return new Promise((resolve, reject) => {
                    api
                        .get("/localsalesorders",{
                            params: {
                                conditions: [
                                    {
                                        field: 'localsalesorders.localsale_id',
                                        value: null
                                    },
                                    {
                                        field: 'localsalesorders.issigned',
                                        value: 1
                                    }
                                ],
                            }
                        })
                        .then(response => {
                            this.allUnInvoicedSalesOrders = response.data.data
                            resolve('done');
                        })
                        .catch(error => {
                            reject(error);
                        })
                })
            },
            loadAnotherActiveLocalSalesInvoice (val) {
                router.push({name: 'update_localsale', params: { localSalesInvoiceId : val}}).catch(err => {})
            },
            loadSalesOrder (val) {
                this.loader = true
                let salesOrder = this.allUnInvoicedSalesOrders.find( s => s.SalesOrder.id == val)
                if (salesOrder) {
                    this.loadUnInvoicedSalesOrder( salesOrder )
                        .then(() => {
                            const buyer = this.allBuyers.find( b => b.Customer.id == this.LocalSalesInvoice__customer_id)
                            if(buyer) {
                                this.filteredBuyers.push(buyer)
                                this.buyerChanged()
                            } else {
                                this.filteredBuyers = this.allBuyers.find(b => b.Company.country_id == this.LocalSalesInvoice__destinatione_id)
                                if(this.filteredBuyers.length == 0){
                                    this.filteredBuyers = this.allBuyers
                                }
                            }
                            let payload = {
                                prefix: this.statePrefix,
                                localsalesorder_id: val
                            }
                            this.getSalesItemsBySalesOrder(payload)
                                .then(() => {
                                    this.loader = false
                                })
                        })
                } else {
                    this.loader = false
                    alert(this.$t('message.localSalesInvoiceCannotInitialize') )
                }
            },
            loadLocalSalesInvoiceById (val) {
                this.loader = true
                this.getLocalSalesInvoiceToUpdateById( val )
                    .then(() => {
                        this.$title = this.$t('message.titles.invoice') + ': ' + this.LocalSalesInvoice__title
                        this.loader = false
                        const buyer = this.allBuyers.find( b => b.Customer.id == this.LocalSalesInvoice__customer_id)
                        if(buyer !== undefined) {
                            this.filteredBuyers.push(buyer)
                        } else {
                            this.filteredBuyers = this.allBuyers.find(b => b.Company.country_id == this.LocalSalesInvoice__destinatione_id)
                            if(this.filteredBuyers.length == 0){
                                this.filteredBuyers = this.allBuyers
                            }
                        }
                        const payload = {
                            localSalesInvoiceId: val,
                            prefix: this.statePrefix
                        }
                        this.getSalesItemsByLocalSaleInvoice(payload)
                             .then(() => {
                                this.updateTotals(this.statePrefix)
                                    .then(() => {
                                        this.officeChanged()
                                        if(this.buyerPaymentTerms.length == 0){
                                            this.buyerPaymentTerms = this.allBuyerLocalSalesPaymentTerms
                                        }
                                        if(this.LocalSalesInvoice__customer_id != null) this.buyerChanged()
                                        this.loader = false
                                        this.updatedLocalSalesInvoiceUnsaved = false
                                    })
                                    .catch(()=>{
                                        this.loader = false
                                        this.updatedLocalSalesInvoiceUnsaved = false
                                    })
                            })
                            .catch(()=>{
                                this.loader = false
                                this.updatedLocalSalesInvoiceUnsaved = false
                            })
                    })
                    .catch(()=>{
                        this.loader = false
                        this.updatedLocalSalesInvoiceUnsaved = false
                        router
                            .push({name: 'not_found_main', params: {resource: 'invoice'}})
                            .catch(() => {
                                console.log('error')
                            })
                    })
            },
            officeChanged () {
                let office = this.offices.find(office => office.Office.id == this.LocalSalesInvoice__office_id)
                this.filteredBuyers = this.allBuyers.filter( buyer => {
                    return buyer.Company.country_id == office?.Office.country_id
                })
                if(this.LocalSalesInvoice__headertaxschedule == null){
                    this.LocalSalesInvoice__headertaxschedule = office.Office.taxcode
                }
            },
            async postToGp(val) {
                if (await this.$root.$confirm(this.$t('message.postToGpQueue'), this.$t('message.confirmations.continuePostToGpQueue'), {color: 'orange'})) {
                    this.loading.post = true
                    const promise = new Promise((resolve, reject) => {
                        api
                            .put("/localsales/" + val + "/post-gp")
                            .then(response => {
                                if (response.data.status == 'success') {
                                    resolve(response.data)
                                } else {
                                    resolve('error')
                                }
                            })
                            .catch(error => {
                                reject(error);
                            })
                    })
                    promise
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.invoicePosted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.Invoice__is_posted = response.is_posted
                                this.Invoice__posted = response.posted
                                this.Invoice__posted_by = response.posted_by
                                this.loading.post = false
                            } else {
                                this.$toast.error(this.$t('message.errors.invoiceNotPosted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.post = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.invoiceNotPosted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.post = false
                        })
                }
            },
            reloadForm () {
                this.loading.reload = true
                window.location.reload()
            },
            reloadItemList (value) {
                if(value.reloadList) {
                    this.loading.items = true
                    this.updateLocalSalesInvoice()
                        .then(() => {
                            this.loading.save = true
                            const payload = {
                                localSalesInvoiceId: this.localSalesInvoiceId,
                                prefix: this.statePrefix
                            }
                            this.getSalesItemsByLocalSaleInvoice(payload)
                                .then(() => {
                                    this.updateTotals(this.statePrefix)
                                        .then(() => {
                                            this.loading.items = false
                                            this.loading.save = false
                                            this.updatedLocalSalesInvoiceUnsaved = false
                                        })
                                        .catch(()=>{
                                            this.loading.items = false
                                            this.loading.save = false
                                            this.updatedLocalSalesInvoiceUnsaved = false
                                        })
                                })
                                .catch(()=>{
                                    this.loading.items = false
                                    this.loading.save = false
                                    this.updatedLocalSalesInvoiceUnsaved = false
                                })
                        })
                        .catch(()=>{
                            this.loading.items = false
                            this.loading.save = false
                            this.updatedLocalSalesInvoiceUnsaved = false
                        })
                } else {
                    if(this.updateMode) {
                        this.loading.items = true
                        this.updateLocalSalesInvoice()
                            .then(() => {
                                this.loading.items = false
                            })
                            .catch(() => {
                                this.loading.items = false
                            })
                    } else {
                        this.updateTotals(this.statePrefix)
                    }
                }
            },
            saveLocalSalesInvoice () {
                if(this.$refs.localSalesInvoiceform.validate()) {
                    this.loading.save = true
                    this.updateLocalSalesInvoice()
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.localSalesInvoiceUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.save = false
                                this.updatedLocalSalesInvoiceUnsaved = false
                            } else {
                                if(isObject(response.message)){
                                    //extract validation errors
                                    const errors = Object.values(response.message)
                                    errors.forEach(errorObj => {
                                        if (Array.isArray(errorObj)) {
                                            errorObj.forEach(error => {
                                                this.$toast.error(error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                        } else {
                                            this.$toast.error(this.$t('message.errors.localSalesInvoiceNotUpdated'),
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        }
                                    })
                                } else {
                                    this.$toast.error(this.$t('message.errors.localSalesInvoiceNotUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                                this.loading.save = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.localSalesInvoiceNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.localSalesInvoiceNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    scrollToFirstFormValidationError()
                }
            },
            async submitToLhdn(val) {
                if (await this.$root.$confirm(this.$t('message.submitToLhdnQueue'), this.$t('message.confirmations.continueSubmissionToLhdnAction'), {color: 'orange'})) {
                    this.loading.lhdn = true
                    let promise = new Promise((resolve, reject) => {
                        api
                            .post("/lhdn/document", {
                                document_id:  val,
                                document_type: 'ws'
                            })
                            .then(response => {
                                if (response.data.status == 'success') {
                                    resolve(response.data)
                                } else {
                                    resolve(response.data.message)
                                }
                            })
                            .catch(() => {
                                reject('error');
                            })
                    })
                    promise
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.invoiceSubmitted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                );
                                this.lhdn.submitted = true;
                                this.loading.lhdn = false;
                                this.lhdn.jobId = response.job_id;
                            } else {
                                this.$toast.error(response,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                );
                                this.loading.lhdn = false;
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.invoiceNotSubmitted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            );
                            this.loading.lhdn = false;
                        })
                }
            },
            updateIndonesiaDocument(field, value) {
                const payload = {
                    field: field,
                    prefix: this.statePrefix,
                    value: value,
                }
                this.$store.commit('localsalesinvoice/updateIndonesiaDocuments', payload)
            },
            updateLhdnStatus() {
                this.loading.updatingLhdnStatus = true;
                api
                    .get("/lhdn/jobs/status/" + this.lhdn.jobId)
                    .then(response => {
                        if (response.data.status == 'success') {
                            this.checkLhdnValidation();
                        } else {
                            this.$toast.error(this.$t('message.errors.statusNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            );
                            this.loading.updatingLhdnStatus = false;
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.statusNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        );
                        this.loading.updatingLhdnStatus = false;
                    })
            },
            async uploadToXero (val) {
                if (await this.$root.$confirm(this.$t('message.uploadToXeroQueue'), this.$t('message.confirmations.continueUploadToXeroAction'), {color: 'orange'})) {
                    this.loading.xero = true
                    let promise = new Promise((resolve, reject) => {
                        api
                            .put("/localsales/" + val + "/upload-xero")
                            .then(response => {
                                if (response.data.status == 'success') {
                                    resolve(response.data)
                                } else {
                                    resolve(response.data.message)
                                }
                            })
                            .catch(error => {
                                reject(error);
                            })
                            .finally(() => {
                                resolve('error')
                            })
                    })
                    promise
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.invoiceUploaded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.LocalSalesInvoice__is_posted = 1
                                this.loading.xero = false
                            } else {
                                this.$toast.error(response,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.xero = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.invoiceNotUploaded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.xero = false
                        })
                }
            },
            viewPdf (docType) {
                let prefix = 'INV'
                if(docType == 'ro') prefix = 'RO'
                if(docType == 'do') prefix = 'DO'
                let document = encodeURIComponent(prefix + " " + this.LocalSalesInvoice__title)
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/localsales/print/'
                    + this.localSalesInvoiceId
                    + '/'
                    + docType
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            viewPdfV2 (docType) {
                let prefix = 'INV'
                if(docType == 'ro') prefix = 'RO'
                if(docType == 'do') prefix = 'DO'
                let document = encodeURIComponent(prefix + " " + this.LocalSalesInvoice__title)
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/localsales/print-v2/'
                    + this.localSalesInvoiceId
                    + '/'
                    + docType
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank");
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        watch: {
            editByDialog(val) {
                if (val) {
                    localStorage.setItem('warehouseSalesEditByDialog2', 'true')
                } else {
                    localStorage.setItem('warehouseSalesEditByDialog2', 'false')
                }
            }
        },
        created () {
            //check editByDialog
            if(localStorage.getItem('warehouseSalesEditByDialog2')){
                this.editByDialog = localStorage.getItem('warehouseSalesEditByDialog2') == 'true' ? true : false
            } else {
                localStorage.setItem('warehouseSalesEditByDialog2','true')
                this.editByDialog = true
            }

            if(this.allActiveSuspendedSuppliers.length == 0) this.getAllActiveSuspendedSuppliers()
            if(this.allBuyers.length == 0) this.getAllActiveBuyers()
            if(this.allBankAccounts.length == 0) this.getAllBankAccounts()
            if(this.allCountries.length == 0) this.getAllCountries()
            if(this.allSalesColleagues.length == 0) this.getAllSalesColleagues()
            if(this.allBuyerPaymentTerms.length == 0) this.getAllPaymentTerms()

            if(this.$route.matched.some(({name}) => name === 'update_localsale')) {
                this.loadLocalSalesInvoiceById(this.localSalesInvoiceId)
                this.getAllActiveLocalSalesInvoices()
                    .then(response => {
                        this.allActiveLocalSalesInvoices = response
                    })
            } else if(this.$route.matched.some(({name}) => name === 'add_localsale')){
                this.getAllUnInvoicedSalesOrders()
                    .then(() => {
                        if(this.salesOrderId != null){
                            //load the corresponding sales order
                            this.loader = true
                            const salesOrder = this.allUnInvoicedSalesOrders.find( s => s.SalesOrder.id == this.salesOrderId)
                            if (salesOrder) {
                                this.loadUnInvoicedSalesOrder( salesOrder )
                                    .then(() => {
                                        const buyer = this.allBuyers.find( b => b.Customer.id == this.LocalSalesInvoice__customer_id)
                                        this.filteredBuyers.push(buyer)
                                        this.buyerChanged()
                                        let payload = {
                                            prefix: this.statePrefix,
                                            localsalesorder_id: this.salesOrderId
                                        }
                                        this.getSalesItemsBySalesOrder(payload)
                                            .then((items) => {
                                                items.forEach(item => {
                                                    if(item.Errors && item.Errors.length > 0){
                                                        this.itemQuantityErrors = true
                                                    }
                                                })
                                                this.loader = false
                                                this.LocalSalesInvoice__localsaledate = new Date().toISOString().substr(0,10)
                                            })
                                    })
                            } else {
                                this.loader = false
                                alert( this.$t('message.localSalesInvoiceCannotInitialize') )
                            }
                        }
                        if(this.LocalSalesInvoice__customer_id != null && this.LocalSalesInvoice__SalesOrder__id != null){
                            //load buyer if stored current local sales invoice detected
                            let buyer = this.allBuyers.find( b => b.Customer.id == this.LocalSalesInvoice__customer_id)
                            this.filteredBuyers.push(buyer)
                            this.buyerChanged()

                            if(this.LocalSalesInvoice__headertaxschedule == null){
                                let office = this.offices.find(o => o.Office.id == this.LocalSalesInvoice__office_id)
                                this.LocalSalesInvoice__headertaxschedule = office.Office.taxcode
                            }
                        }
                    })
            }
        },
        mounted () {
            this.$store.watch(
                function (state) {
                    return state.appic.localsalesinvoice.update.LocalSalesInvoice
                },
                () => {
                    this.updatedSalesOrderUnsaved = true
                },
                {deep: true}
            )
            this.$store.watch(
                function (state) {
                    return state.appic.salesorder.current.SalesOrder
                },
                () => {
                    this.newSalesOrderUnsaved = true
                },
                {deep: true}
            )
            this.$nextTick(function() {
                if (this.$route.matched.some(({name}) => name === 'update_localsale')) {
                    this.checkLhdnValidation();
                }
            })
        }
    }
</script>

<style>
    .force-text-left .v-text-field__slot input {
        text-align: left !important;
    }
    .v-dialog {
        min-width: 200px !important;
    }
    .wrap-text {
        white-space: normal;
    }
    .font-xs {
        font-size: small;
    }
    .v-enter-active,
    .v-leave-active {
        transition: opacity 0.5s ease;
    }
    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
</style>